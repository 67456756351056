@tailwind base;
@tailwind components;
@tailwind utilities;

@import './fonts.css';
@import './comic-viewer.css';
@import './Drawer.module.css';
@import './gradients.css';
@import './utilities.css';
@import './background-images.css';

:root,
#root,
#docs-root {
  --primary: #1f1f5780;
  --accent: #8f00ff;
  --button-bg-primary: #0f0f37;
  --button-bg-selected: #1f1f57;
  --button-secondary: #8f00ff;
  --button-bg-green: rgba(118, 240, 97, 0.5);
  --button-bg-red: rgba(255, 0, 46, 0.5);
  --button-bg-selected-50: rgba(31, 31, 87, 0.5);
}


@layer utilities {
  .bg-primary {
    background-color: var(--primary);
  }

  .bg-purple {
    background-color: var(--accent);
  }

  .bg-green-gradient {
    background: linear-gradient(135deg,
        rgba(1, 147, 128, 0.5) 0%,
        rgba(15, 15, 55, 0) 100%),
      #000000;
  }

  .button-bg-primary {
    background-color: var(--button-bg-primary);
  }

  .button-bg-secondary {
    background-color: var(--button-secondary);
  }

  .button-bg-selected {
    background-color: var(--button-bg-selected);
  }

  .button-bg-green {
    background-color: var(--button-bg-green);
  }

  .button-bg-red {
    background-color: var(--button-bg-red);
  }

  .border-7B61FF {
    border-color: #7b61ff;
  }

  .gra-7B61FF {
    background: linear-gradient(137.42deg,
        rgba(123, 97, 255, 0.5) 5%,
        rgba(15, 15, 55, 0) 93.91%);
  }
}

.nft-hover:hover {
  @apply border-7B61FF gra-7B61FF;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Barlow Semi Condensed', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  color: white;
  font-weight: normal;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bebas Neue';
}

main {
  min-height: 100%;
  padding: 0rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5rem;
  max-width: 1100px;
  margin: auto;
}

p { 
  line-height: 19px;
}

@media only screen and (max-width: 639px) {
  main {
    margin-top: 4rem;
  }
}

.sb-container {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  z-index: 999;
}

.toasty {
  inset: 0 !important;
}

/* .toasty div {
  position: relative !important;
} */

.header {
  height: 79px;
  width: 1920px;
}

.frame-8-7 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.625rem;
}

.frame-9-0 {
  border-radius: 12.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.875rem 1.625rem;
  background-color: rgba(31, 31, 87, 0.5);
  border: 0.125rem solid rgba(38, 45, 90, 0.75);
}

.text-1 {
  text-align: center;
  vertical-align: top;
  font-size: 14px;
  font-family: Manrope;
  letter-spacing: -4%;
  line-height: auto;
  color: #edeef4;
}

.frame-8-8 {
  border-radius: 12.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.125rem 0.125rem 0.125rem 0.875rem;
  gap: 0.375rem;
  background-color: rgba(31, 31, 87, 0.5);
  border: 0.125rem solid rgba(38, 45, 90, 0.75);
}

.frame-7-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.875rem;
}

.frame-7-3 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.img-2 {
  height: 24px;
  width: 24px;
}

.text-3 {
  text-align: center;
  vertical-align: top;
  font-size: 14px;
  font-family: Manrope;
  letter-spacing: -4%;
  line-height: auto;
  color: #edeef4;
}

.frame-2-3 {
  border-radius: 12.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 1.875rem;
  gap: 0.625rem;
  background-color: #8e00ff;
  border: 0.125rem solid rgba(38, 45, 90, 0.75);
}

.text-4 {
  text-align: center;
  vertical-align: top;
  font-size: 14px;
  font-family: Manrope;
  letter-spacing: -4%;
  line-height: auto;
  color: #ffffff;
}

.frame-8-6 {
  border-radius: 12.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0.875rem 1.625rem;
  background-color: rgba(31, 31, 87, 0.5);
  border: 0.125rem solid rgba(38, 45, 90, 0.75);
}

.text-5 {
  text-align: center;
  vertical-align: top;
  font-size: 14px;
  font-family: Manrope;
  letter-spacing: -4%;
  line-height: auto;
  color: #edeef4;
}

.frame-8-9 {
  border-radius: 12.5rem;
  height: 47px;
  width: 134px;
  background-color: rgba(31, 31, 87, 0.5);
  border: 0.125rem solid rgba(38, 45, 90, 0.75);
}

.frame-7-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.text-6 {
  text-align: center;
  vertical-align: top;
  font-size: 14px;
  font-family: Manrope;
  letter-spacing: -4%;
  line-height: auto;
  color: #edeef4;
}

.frame-7-4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.6875rem;
}

.logo {
  height: 50px;
  width: 147px;
}

.icon {
  height: 50px;
  width: 50px;
}

.ellipse-4-7 {
  height: 50px;
  width: 50px;
}

.frame-7-5 {
  border-radius: 12.5rem;
  height: 47px;
  width: 260px;
  background-color: rgba(31, 31, 87, 0.5);
  border: 0.125rem solid rgba(38, 45, 90, 0.75);
}

.frame-6-6 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.img-7 {
  height: 24px;
  width: 24px;
}

.text-8 {
  text-align: center;
  vertical-align: top;
  font-size: 14px;
  font-family: Manrope;
  letter-spacing: -4%;
  line-height: auto;
  color: #ffffff;
}

.img-9 {
  transform: rotate(-1deg);
  height: 18px;
  width: 18px;
}

.portal-enter {
  opacity: 0;
}

.portal-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.portal-exit {
  opacity: 1;
}

.portal-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}


.battlefly-image-button:hover {
  background-size: 105%;
}

.battlefly-image-button {
  background-size: 100%;
  transition: all 0.5s;
}

.star-rating {
  display: flex;
  justify-content: center;
  gap: 4px;
}

.c-star {
  /* width: var(--size, 0.5rem); */
  fill: #f2f2f235;
}

.c-star.active {
  fill: #dfb300;
}

.nav-item {
  @apply mr-2 items-center justify-center rounded-3xl py-3 px-6 text-sm text-white;
  background-color: rgba(31, 31, 87, 70%);
}

.button {
  @apply m-1 items-center justify-center rounded-2xl text-center text-white transition delay-100 ease-in-out;
}

.dropdown-default {
  @apply button-bg-primary items-center justify-center overflow-visible rounded-lg py-4 text-center text-white transition delay-100 ease-in-out;
}

@layer utilities {
  .primary-border {
    @apply border-[1px] border-button-default;
  }

  .yellow-border {
    border-color: #ffd02c;
  }
}

.box-caption {
  background: #0f0f37;
  box-shadow: 0px -10px 150px #05051f;
  backdrop-filter: blur(9px);
  border-radius: 0px 0px 14px 14px;
}

.battlefly-card-wrapper {
  overflow: hidden;
  z-index: 10;
}

.battlefly-screen {
  /* This line:
   1. blurs the background so we can see it around the 
         image as "glow"
   2. increases the background saturation so we get colors 
         back after the shadow effect dulls them out
  */
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);

  /* These lines prevent the border box from clipping the blur effect */
}

.battlefly-card {
  transform: scale(1);
  transition: all 0.5s;
}

.battlefly-card:hover {
  transform: scale(1.03);
}

.battlefly-card-bg:hover {
  transform: scale(0.9);
}

.image-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}

.image-child-back {
  grid-area: 1 / 1 / 2 / 2;
}

.image-child-screen {
  grid-area: 1 / 1 / 2 / 2;
}

.image-child-front {
  grid-area: 1 / 1 / 2 / 2;
}

.toast-success {
  background-color: #019380;
}

.toast-warning {
  background-color: #db6e00;
}

.toast-rejected {
  background-color: #ea404e;
}

.toast-brown {
  background-color: #404040;
}

.font-success {
  color: #06b59e;
}

.font-rejected {
  color: #ea404e;
}

.border-rejected {
  border-color: #ea404e;
}

.border-success {
  border-color: #019380;
}

.hide-network-switch {
  height: 0px;
  transition: height 1s;
}

.reveal-network-switch {
  transition: height 1s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* input[type='checkbox'] {
  position: relative;
  width: 0.9rem;
  height: 0.9rem;
  color: #363839;
  border: 1px solid #7b61ff;
  background-color: white;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);
}

input[type='checkbox']::before {
  position: absolute;
  content: '';
  display: block;
  top: 1px;
  left: 4px;
  width: 5px;
  height: 8px;
  border-style: solid;
  border-color: #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  opacity: 0;
}

input[type='checkbox']:checked {
  color: #fff;
  border-color: #7b61ff;
  background: #7b61ff;
}

input[type='checkbox']:checked::before {
  opacity: 1;
} */

input[type='checkbox']:checked~label::before {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.bg-green-gradient {
  background: linear-gradient(135deg,
      rgba(1, 147, 128, 0.5) 0%,
      rgba(15, 15, 55, 0) 100%),
    #000000;
}

input[type='checkbox'].mc-card-checkbox {
  width: 18px;
  height: 18px;
  border-radius: 1.5px;
}

input[type='checkbox'].mc-card-checkbox::before {
  top: 3px;
  left: 6px;
}

.fade-in-game {
  animation: fadeIn 0.7s;
  -webkit-animation: fadeIn 0.7s;
  -moz-animation: fadeIn 0.7s;
  -o-animation: fadeIn 0.7s;
  -ms-animation: fadeIn 0.7s;
}

.fade-out-game {
  animation: fadeOut 0.7s forwards;
  -webkit-animation: fadeOut 0.7s forwards;
  -moz-animation: fadeOut 0.7s forwards;
  -o-animation: fadeOut 0.7s forwards;
  -ms-animation: fadeOut 0.7s forwards;
}

.scrollbar-customized {
  scrollbar-width: thin;
  scrollbar-color: blue rgba(31, 31, 87, 0.8);
}

/* Works on Chrome, Edge, and Safari */
.scrollbar-customized::-webkit-scrollbar {
  width: 8px;
}

.scrollbar-customized::-webkit-scrollbar-track {
  background: rgba(31, 31, 87, 0.8);
  border-radius: 20px;
}

.scrollbar-customized::-webkit-scrollbar-thumb {
  background-color: blue;
  border-radius: 20px;
  border: none;
}

@keyframes fadeInGame {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOutGame {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  -moz-animation: fadeIn 1s;
  -o-animation: fadeIn 1s;
  -ms-animation: fadeIn 1s;
}

.fade-out {
  animation: fadeOut 2s forwards;
  -webkit-animation: fadeOut 2s forwards;
  -moz-animation: fadeOut 2s forwards;
  -o-animation: fadeOut 2s forwards;
  -ms-animation: fadeOut 2s forwards;
}

.fade-out-toast {
  animation: fadeOut 1s forwards;
  -webkit-animation: fadeOut 1s forwards;
  -moz-animation: fadeOut 1s forwards;
  -o-animation: fadeOut 1s forwards;
  -ms-animation: fadeOut 1s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in-black-bg {
  animation: fadeInBlack 5s forwards;
  -webkit-animation: fadeInBlack 5s forwards;
  -moz-animation: fadeInBlack 5s forwards;
  -o-animation: fadeInBlack 5s forwards;
  -ms-animation: fadeInBlack 5s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeInBlack {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }

  100% {
    background-color: rgba(0, 0, 0, 1);
  }
}

.revealing-button {
  border: 1px solid #7b61ff;

  background: linear-gradient(145deg,
      rgba(123, 97, 255, 0.5) 5%,
      rgba(15, 15, 55, 0) 50%,
      rgba(123, 97, 255, 0.5) 99%);
  background-size: 400% 400%;
  animation: gradient 2s ease infinite;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.f-size-42 {
  font-size: 42px;
}

.bg-FF002E {
  background: #ff002e;
}

.bg-rgba-31-31-87-08 {
  background: rgba(31, 31, 87, 0.8);
}

.mw-hd-battle-result-plate {
  min-width: 300px;
}

.bg-accent {
  background: var(--accent);
}

.mw-hd-battle-result-plate .Tooltip {
  z-index: 10;
}

@media screen and (min-width: 768px) {
  .mw-hd-battle-result-plate {
    min-width: 175px;
    max-width: 200px;
  }
}

@media screen and (max-width: 599px) {
  .no-backgrounds-mobile, .no-backgrounds-mobile > *{
    background: none;
  }
  .revealing_video_container>img.fade-in,
  .revealing_video_container>div.image-child-back {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .mw-hd-battle-result-plate {
    min-width: 175px;
    max-width: 300px;
  }
}

@media screen and (min-width: 1920px) {
  .mw-hd-battle-result-plate {
    min-width: 568px;
  }
}

a {
  width: 100%;
  text-align: center;
}

.mw-hd-battle-result-plate {
  /*min-width: 180px;*/
}

.battle-log-height {
  height: calc(100% - 5.4rem);
  background: rgba(5, 5, 31, 0.4);
}

.pill {
  background: linear-gradient(137.42deg,
      rgba(123, 97, 255, 0.5) 5%,
      rgba(15, 15, 55, 0) 93.91%);
}

.background-gradient-purple {
  background: linear-gradient(137.42deg,
      rgba(123, 97, 255, 0.5) 5%,
      rgba(15, 15, 55, 0) 93.91%);
}

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.scrollable-element-container {
  height: 100%;
  max-height: max-content;
}

.scrollable-element-wrapper {
  height: 100%;
  overflow: hidden;
  max-height: max-content;
  display: flex;
  flex-direction: column;
}

.scrollable-content {
  overflow: auto;
  height: 100%;
  max-height: max-content;
}

/* .filter-dropdown {
  max-height: 0;
	overflow: hidden;
  transition: max-height 0.8s;

} */

/* .filter-dropdown-active {
  max-height: 100%;
  transition: max-height 0.8s;
} */

/* --- Mission Control styles */

.mc-label {
  text-transform: uppercase;
  border-radius: 2px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  padding: 4px;
  font-weight: 500;
  font-family: 'IBM Plex Mono', sans-serif;
  line-height: 1.4;
}

.mc-label-primary {
  background: linear-gradient(137.42deg,
      rgba(123, 97, 255, 0.5) 5%,
      rgba(15, 15, 55, 0) 93.91%);
  filter: drop-shadow(1px 1px 0px #7b61ff);
  border-color: #7b61ff;
}

.mc-label-secondary {
  background: linear-gradient(135deg,
      rgba(1, 147, 128, 0.5) 0%,
      rgba(15, 15, 55, 0) 100%);
  filter: drop-shadow(1px 1px 0px #06b59e);
  border-color: #06b59e;
}

.mc-label-sm {
  font-size: 8px;
  height: 18px;
}

.mc-label-md {
  font-size: 16px;
  height: 29px;
}

.mc-in-garden-card {
  background: linear-gradient(135deg,
      rgba(255, 208, 44, 0.5) 0%,
      rgba(15, 15, 55, 0) 100%);
}

.mc-in-hyperdome-card {
  background: linear-gradient(135deg,
      #7b61ff 0%,
      rgba(15, 15, 55, 0) 100%);
}

.mc-status {
  border-radius: 2px;
  border: 1px solid #06b59e;
}

.content-black-bg-full-page {
  position: relative;
  padding-left: 100px;
  width: calc(100% + 2rem);
  left: -1rem;
  top: -10px;
  min-height: calc(100vh - 72px);
}

.max-content-width {
  /* max-width: 2160px; */
  max-width: calc(100% - 80px);
  margin: 0 auto;

  
}

@media screen and (min-width: 1920px) {
  .max-content-width {
    max-width: 2160px;
  }
}

@media screen and (max-width: 639px) {
  .max-content-width {
    max-width: 100%;
  }
}


.mc-mod-type-card-active {
  position: relative;
  background: linear-gradient(135deg,
      rgba(1, 147, 128, 0.5) 0%,
      rgba(15, 15, 55, 0) 100%),
    #000000;
}

.mc-mod-type-card-active .checkmark {
  content: '';
  width: 24px;
  height: 24px;
  position: absolute;
  background-color: #019380;
  border-radius: 50%;
  top: -10px;
  right: -10px;
}

.mod__body {
  background: rgba(39, 38, 38, 0.75);
  border: 1px solid #7b61ff;
  backdrop-filter: blur(45px);
}

.mod__image {
  background: linear-gradient(137.42deg,
      rgba(156, 112, 79, 0.5) 5%,
      rgba(156, 112, 79, 0) 93.91%),
    #000000;
  box-shadow: 1px 1px 0px #9c704f;
}

.mod__effect {
  background: linear-gradient(137.42deg,
      rgba(156, 112, 79, 0.5) 5%,
      rgba(156, 112, 79, 0) 93.91%);
  border: 1px solid #5a3f2b;
}

.bg__brown {
  background: #5a3f2b;
}

.bg_mod_dropzone {
  border: 2px dashed #c2bbbb;
  border-radius: 2px;
}

.mod__compact_row_slider_container>div {
  width: 100%;
}

.hatchery_advanced_filter_btn_container>button {
  height: 100%;
  background: transparent;
}

.hatchery_advanced_filter_btn_container span {
  font-weight: normal;
}

.hatchery_cocooon_card_revealing input {
  display: none;
}

.revealing_video_container video {
  max-width: 100%;
  max-height: 100%;
  border-radius: 0;
}

.sidebar_fade_in {
  transition: width 1s ease;
  width: 600px;
}

.sidebar_fade_out {
  transition: width 1s ease;
  width: 0;
}

.hatchery_cocooon_card>div {
  background: none;
}

.border-radius-5px {
  border-radius: 5px;
}

.battle_log_list_card {
  border: 1px solid #4d4d4d;
}

.battle_log_list_card.battle-live {
  border-color: #019380;
}

.battle_log_list_card_bg_green {
  background: linear-gradient(135deg,
      rgba(1, 147, 128, 0.5) 0%,
      rgba(15, 15, 55, 0) 100%),
    #000000;
}

.battle_log_list_card_bg_red {
  background: linear-gradient(135deg,
      rgba(234, 64, 78, 0.5) 0%,
      rgba(15, 15, 55, 0) 100%),
    #000000;
}

.letter-spacing-008 {
  letter-spacing: 0.08em;
}

.button-green-gradient {
  background: linear-gradient(135deg,
      rgba(1, 147, 128, 0.5) 0%,
      rgba(15, 15, 55, 0) 100%);
  border: 2px solid #019380;
  filter: drop-shadow(0px 0px 150px rgba(5, 5, 31, 0.5));
  backdrop-filter: blur(20px);
  border-radius: 2px;
}

.mod-draggable-scavenge>div:first-child {
  height: 100%;
}

.revealing_video_container_mobile>img.fade-in {
  display: none !important;
}

.revealing_video_container_mobile {
  display: none;
}

.styled-input {
  border-radius: 8px;
  height: 4px;
  outline: none;
  -webkit-appearance: none;
}

input.styled-input[type='range']::-webkit-slider-thumb {
  border: 2px solid #58dcdf;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background: #58dcdf;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: 0;
}

.rift_battleflys_cards_container>div {
  background: none;
}

.scrollable-element-container {
  height: 100%;
  max-height: max-content;
}

.scrollable-element-wrapper {
  height: 100%;
  overflow: hidden;
  max-height: max-content;
  display: flex;
  flex-direction: column;
}

.scrollable-content {
  overflow: auto;
  height: 100%;
  max-height: max-content;
}

.ul-styled-blue-rectangle>li {
  position: relative;
  padding-left: 20px;
}

.ul-styled-blue-rectangle>li:before {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background: #2364FF;
  border: 1px solid #B2E4FF;
  transform: rotate(45deg);
  top: 10px;
  left: 0px;
}

select.no-arrow-appearance {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

select.custom-arrow-appearance-v {
  position: relative;
}

select.custom-arrow-appearance-v option {
  background: rgb(16, 13, 53);
}

.custom-arrow-appearance-v-container:after {
  content: '>';
  position: absolute;
  right: 18px;
  top: calc(50% - 13px);
  color: black;
  pointer-events: none;
  transform: rotate(90deg);
  font-size: 24px;
}

.transform-minus-50-0 {
  transform: translate(-50%, 0%);
}
.Tooltip{
  z-index: 60;
}
.bg-primary-gradient{
  background: linear-gradient(150.65deg, #5C5C98 0.59%, rgba(15, 15, 55, 0) 98.84%);
}

input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }