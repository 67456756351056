

@font-face {
  font-family: 'Bebas Neue';
  src: url('/fonts/BebasNeue/BebasNeue-Regular.eot');
  src: local('Bebas Neue Regular'), local('BebasNeue-Regular'),
      url('/fonts/BebasNeue/BebasNeue-Regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BebasNeue/BebasNeue-Regular.woff2') format('woff2'),
      url('/fonts/BebasNeue/BebasNeue-Regular.woff') format('woff'),
      url('/fonts/BebasNeue/BebasNeue-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Semi Condensed';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-BoldItalic.eot');
  src: local('Barlow Semi Condensed Bold Italic'), local('BarlowSemiCondensed-BoldItalic'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-BoldItalic.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-BoldItalic.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow Semi Condensed';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-BlackItalic.eot');
  src: local('Barlow Semi Condensed Black Italic'), local('BarlowSemiCondensed-BlackItalic'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-BlackItalic.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-BlackItalic.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow Semi Condensed';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Black.eot');
  src: local('Barlow Semi Condensed Black'), local('BarlowSemiCondensed-Black'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Black.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Black.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Black.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Semi Condensed';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Bold.eot');
  src: local('Barlow Semi Condensed Bold'), local('BarlowSemiCondensed-Bold'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Bold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Bold.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Bold.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Semi Condensed';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraBold.eot');
  src: local('Barlow Semi Condensed ExtraBold'), local('BarlowSemiCondensed-ExtraBold'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraBold.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraBold.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Semi Condensed ExLight';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraLight.eot');
  src: local('Barlow Semi Condensed ExtraLight'), local('BarlowSemiCondensed-ExtraLight'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraLight.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraLight.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraLight.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Semi Condensed';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Italic.eot');
  src: local('Barlow Semi Condensed Italic'), local('BarlowSemiCondensed-Italic'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Italic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Italic.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Italic.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow Semi Condensed';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraBoldItalic.eot');
  src: local('Barlow Semi Condensed ExtraBold Italic'), local('BarlowSemiCondensed-ExtraBoldItalic'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraBoldItalic.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraBoldItalic.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraBoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow Semi Condensed ExLight';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraLightItalic.eot');
  src: local('Barlow Semi Condensed ExtraLight Italic'), local('BarlowSemiCondensed-ExtraLightItalic'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraLightItalic.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraLightItalic.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow Semi Condensed';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Regular.eot');
  src: local('Barlow Semi Condensed Regular'), local('BarlowSemiCondensed-Regular'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Regular.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Regular.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Semi Condensed';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Medium.eot');
  src: local('Barlow Semi Condensed Medium'), local('BarlowSemiCondensed-Medium'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Medium.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Medium.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Medium.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Semi Condensed';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-SemiBold.eot');
  src: local('Barlow Semi Condensed SemiBold'), local('BarlowSemiCondensed-SemiBold'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-SemiBold.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-SemiBold.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Semi Condensed';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-MediumItalic.eot');
  src: local('Barlow Semi Condensed Medium Italic'), local('BarlowSemiCondensed-MediumItalic'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-MediumItalic.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-MediumItalic.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow Semi Condensed';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-LightItalic.eot');
  src: local('Barlow Semi Condensed Light Italic'), local('BarlowSemiCondensed-LightItalic'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-LightItalic.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-LightItalic.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow Semi Condensed';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Light.eot');
  src: local('Barlow Semi Condensed Light'), local('BarlowSemiCondensed-Light'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Light.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Light.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Light.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Semi Condensed';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Thin.eot');
  src: local('Barlow Semi Condensed Thin'), local('BarlowSemiCondensed-Thin'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Thin.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Thin.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Thin.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Barlow Semi Condensed';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-SemiBoldItalic.eot');
  src: local('Barlow Semi Condensed SemiBold Italic'), local('BarlowSemiCondensed-SemiBoldItalic'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-SemiBoldItalic.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-SemiBoldItalic.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Barlow Semi Condensed';
  src: url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ThinItalic.eot');
  src: local('Barlow Semi Condensed Thin Italic'), local('BarlowSemiCondensed-ThinItalic'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ThinItalic.woff2') format('woff2'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ThinItalic.woff') format('woff'),
      url('/fonts/BarlowSemiCondensed/BarlowSemiCondensed-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Inter';
  src: url('/fonts/Inter/Inter-Regular.eot');
  src: local('Inter Regular'), local('Inter-Regular'),
      url('/fonts/Inter/Inter-Regular.eot?#iefix') format('embedded-opentype'),
      url('/fonts/Inter/Inter-Regular.woff2') format('woff2'),
      url('/fonts/Inter/Inter-Regular.woff') format('woff'),
      url('/fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
