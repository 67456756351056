.scavenger-matrix-transform {
  transform: matrix(0.96, 0.22, -0.3, 0.97, 0, 0);
}

@media screen and (min-width: 1280px) {
  .max-width-loadout-section {
      max-width: calc(100%  - 380px);
    }
}

.hunters-mark-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 1rem;
}

@media screen and (min-width: 1024px) {
  .hunters-mark-grid  {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
}

.stat-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
}

@media screen and (min-width: 1024px) {
  .stat-card-grid  {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media screen and (max-width: 1279) {
  .bg-md-and-up {
    background: none !important;
  }
}

.checkbox-round {
  width: 2em;
  height: 2em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: #FF84B5;
  border: 1px solid #FF2079;
}

.checkbox-round:checked::after {
  content: " \2713";
  display: block;
  text-align: center;
  color: white;
  font-size: 1.2em;
}

.dashed-line {
    margin: 0px 0;
    height: 2px;
    background:
      repeating-linear-gradient(180deg,#fff 0 3px,#0000 0 5px)
      /*3px red then 4px transparent -> repeat this!*/
}