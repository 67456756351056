/* .react-pdf__Page__canvas {
  max-width: 100%;
  height: auto !important;
} */

.react-pdf__Document {
  overflow: scroll;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
  max-width: 100%;
  width: 100% !important;
  height: 100% !important;
}

.react-pdf__Page {
  height: 100% !important;
}
