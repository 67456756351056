
.gra-bf-card-border {
  background: linear-gradient(to bottom right,
    rgba(44, 42, 42, 0.8) 0%,
    rgba(0, 0, 0, 1) 40%,
    rgba(37, 33, 55, 0.5) 55%,
    rgba(16, 183, 173, 0.22) 100%)
}

/* .gra-bf-card {
  background: linear-gradient(to bottom right, 
  rgba(0, 0, 0, 1) 26%,
  rgba(37, 33, 55, 0.5) 55%,
  rgba(16, 183, 173, 0.32) 100%);
} */

.box-shadow-card {
  box-shadow: 2px 6px 15px 2px rgba(0, 0, 0, 0.6);
}

.gra-bf-card {
  background: linear-gradient(132.21deg, rgba(37, 33, 55, 0.5) 54.94%, rgba(16, 183, 173, 0.42) 168.44%);
  box-shadow: 2px 6px 15px 2px rgba(12, 10, 11, 0.8);
}

.gra-card-border {
  background: linear-gradient(to bottom, #2C2A2A 0%, #000000 100%);
}

.gra-card {
  background: linear-gradient(114.89deg, rgba(37, 33, 55, 0.5) 50.45%, rgba(57, 34, 74, 0.42) 143.66%);
  box-shadow: inset 2px 6px 15px 2px rgba(12, 10, 11, 0.8);
}

.gra-card.active {
  background: linear-gradient(127.11deg, rgba(37, 33, 55, 0.5) -8.2%, #C4C4C4 191.9%);
  box-shadow: 0px 0px 12px #5BF8FC, inset 2px 6px 15px 2px rgba(0, 0, 0, 0.6);
}

.gra-card-square {
  position: relative;
  background: linear-gradient(127.18deg, rgba(37, 33, 55, 0.5) 23.66%, #0E1730 303.13%);
  box-shadow: inset 2px 6px 15px 2px rgba(0, 0, 0, 0.6);
}

.gra-card-mark {
  background: linear-gradient(127.11deg, rgba(37, 33, 55, 0.5) -8.2%, #C4C4C4 191.9%);
  box-shadow: inset 2px 6px 15px 2px rgba(0, 0, 0, 0.6);
}

.gra-card-mark.active {
  background: linear-gradient(127.11deg, rgba(37, 33, 55, 0.5) -8.2%, #C4C4C4 191.9%);
  box-shadow: 0px 0px 12px #5BF8FC, inset 2px 6px 15px 2px rgba(0, 0, 0, 0.6);
}

.gra-card-blue-mint {
  position: relative;
  background: #07747A;
}

.gra-card-leaderboard {
  background: linear-gradient(127.18deg, rgba(37, 33, 55, 0.5) 23.66%, rgba(129, 54, 189, 0.42) 303.13%);
  box-shadow: inset 2px 6px 15px 2px rgba(0, 0, 0, 0.6);
}

.gra-card-square-border {
    background: linear-gradient(to bottom,
    rgba(44, 42, 42, .5) 0%,
    rgba(0, 0, 0, .5) 100%);
}

.gra-card-blue-mint-border {
  background: linear-gradient(to bottom,
  rgba(44, 42, 42, .5) 0%,
  #07747A 100%);
}

.gra-card-dark-blue {
  background: linear-gradient(127.18deg, rgba(37, 33, 55, 0.5) 23.66%, rgba(129, 54, 189, 0.42) 303.13%);
  box-shadow: inset 2px 6px 15px 2px rgba(0, 0, 0, 0.6);
}

.gra-card-dark-orchid-border {
  background: linear-gradient(to bottom,
    rgba(44, 42, 42, 0.8) 0%,
    rgba(129, 54, 189, 0.8) 100%);
}

.gra-card-pink {
  background: #7D0334;
  box-shadow: 2px 6px 15px 2px rgba(12, 10, 11, 0.8);
}

.gra-card-pink-border {
  background: linear-gradient(to bottom,
    rgba(44, 42, 42, 0.8) 0%,
    rgba(255, 32, 121, 0.8) 100%);
}

.gra-card-empty-slot {
  background: linear-gradient(127.18deg, rgba(37, 33, 55, 0.5) 23.66%, #0E1730 303.13%);
  box-shadow: inset 2px 6px 15px 2px rgba(0, 0, 0, 0.6);
}

.gra-card-gfly {
  background: linear-gradient(127.18deg, rgba(37, 33, 55, 0.5) 23.66%, #13EBD6 303.13%);
  box-shadow: inset 2px 6px 15px 2px rgba(0, 0, 0, 0.6);
}

.gra-card-treasure {
  background: linear-gradient(127.18deg, rgba(37, 33, 55, 0.5) 23.66%, #7B0404 303.13%);
  box-shadow: inset 2px 6px 15px 2px rgba(0, 0, 0, 0.6);
}

button.gra-button-pink {
  background-image: linear-gradient( #FF2079, #FF0267, #FF2079);
}

button.gra-button-gray {
  background-image: linear-gradient(147.79deg, #373541 -201.88%, #272335 73.1%);
}
button.gra-button-gray {
  background-image: linear-gradient(147.79deg, #373541 -201.88%, #272335 73.1%);
}
button.gra-blue-mint {
  background-image: linear-gradient(170.32deg, #5BF8FC 8.05%, rgba(28, 152, 155, 0.51) 187.29%);
}
button.gra-purple {
  background: linear-gradient(170.32deg, #9028AA 8.05%, #3C0B48 187.29%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}


.gra-button-pink-border {
  background-image:  radial-gradient(rgba(255, 32, 121, 1) 0%, rgba(100, 100, 100, 0.2) 100%);
}
.gra-button-gray-border {
  background-image:  radial-gradient(#404047, #2c2938 100%);
}
.gra-button-blue-mint-border {
  background-color: #58dcdf;
}

.gra-green-dot {
  background: #34EEA6;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.gra-red-dot {
  background: #FF5D5D;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.gra-gray-dot {
  background: rgba(217, 217, 217, 0.25);
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.gra-stats-pink {
  background: linear-gradient(90deg, rgba(255, 32, 121, 0.35) -32.44%, #1B1626 93.41%);
}
.gra-stats-green {
  background: linear-gradient(90deg, rgba(52, 238, 166, 0.15) -32.44%, #1B1626 93.41%);
}
.gra-stats-red {
  background: linear-gradient(90deg, rgba(255, 0, 0, 0.175) -32.44%, rgba(27, 22, 38, 0.5) 93.41%);
}


.gra-wasteland-card {
  background: radial-gradient(112.67% 60.65% at -1.73% 20.5%, #091A41 0%, #00020A 100%);
}

.gra-league-larvae-border {
  background: linear-gradient(180deg, #00FF66 0%, rgba(0, 0, 0, 0.26) 100%);
}
.gra-league-larvae-border.active {
  box-shadow: 0px 0px 10px #34EEA6;
}
.gra-league-pupa-border {
  background: linear-gradient(180deg, #5BF8FC 0%, rgba(0, 0, 0, 0.26) 100%);
}
.gra-league-pupa-border.active {
  box-shadow: 0px 0px 10px #5BF8FC;
}
.gra-league-monarch-border {
  background: linear-gradient(180deg, #9747FF 0%, rgba(0, 0, 0, 0.26) 100%);
}
.gra-league-monarch-border.active {
  box-shadow: 0px 0px 10px #8136BD;
}
.gra-league-predator-border {
  background: linear-gradient(180deg, #F57700 0%, rgba(0, 0, 0, 0.26) 100%);
}
.gra-league-predator-border.active {
  box-shadow: 0px 0px 10px #F57700;
}
.gra-league-apex-border {
  background: linear-gradient(180deg, #FF0202 0%, rgba(0, 0, 0, 0.26) 100%);
}
.gra-league-apex-border.active {
  box-shadow: 0px 0px 10px #FF0202;
}

.gra-league-bg {
  background: rgba(32, 32, 32, 1);
  box-shadow: 2px 6px 15px 2px rgba(12, 10, 11, 0.8);
}

/* .gra-scavenging-mark-neutral {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url('/assets/icons/scavenging-mark-gold.svg');
  transform: matrix(0.96, 0.22, -0.3, 0.97, 0, 0);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: -10% -10%;
} */

.rounded-corners-gradient-borders {
  /* width: 300px; */
  /* height: 80px; */
  /* border: double 4px transparent; */
  /* border-radius: 80px; */
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #f00,#3020ff);
  background-origin: border-box;
  background-clip: padding-box, border-box;
}